<template>
  <section class="col-span-12">
    <!-- <div class="col-12 q-mt-md"> -->
    <div id="mapPlace"></div>
    <!-- </div> -->
  </section>
</template>

<script>
/* eslint-disable */
import L from 'leaflet'
// import { OpenStreetMapProvider } from 'leaflet-geosearch'

export default {
  name: 'app-place-form-location',
  props: {
    modelValue: {
      type: Object
    },
    radius: {
      type: Number
    },
    fillColor: {
      type: String
    },
    strokeColor: {
      type: String
    }
  },
  data() {
    return {
      location: '',
      locationResults: [],
      map: null,
      marker: null,
      circle: null,
      popupShow: false,
      loadingLocation: false
    }
  },
  watch: {
    radius(newval) {
      if (newval) {
        this.circle.setRadius(newval)
      }
    },
    fillColor(newval) {
      if (newval) {
        this.circle.setStyle({
          fillColor: newval,
          color: this.color
        })
      }
    },
    strokeColor(newval) {
      if (newval) {
        this.circle.setStyle({
          fillColor: this.fillColor,
          color: newval
        })
      }
    }
  },
  mounted() {
    const greenIcon = L.icon({
      iconUrl: '/images/map/marker2.svg',
      iconSize: [38, 95], // size of the icon
      shadowSize: [50, 64], // size of the shadow
      iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
      shadowAnchor: [4, 62], // the same for the shadow
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    })

    this.map = L.map('mapPlace').setView(
      [this.modelValue.address.lat, this.modelValue.address.long],
      13
    )
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map)

    this.marker = L.marker(
      [this.modelValue.address.lat, this.modelValue.address.long],
      {
        icon: greenIcon,
        draggable: true
      }
    ).addTo(this.map)

    this.marker.on('dragend', this.onMarkerDrag)
    this.circle = L.circle(
      [this.modelValue.address.lat, this.modelValue.address.long],
      {
        color: this.modelValue.strokeColor,
        fillColor: this.modelValue.fillColor,
        fillOpacity: 0.5,
        radius: this.modelValue.radius
      }
    ).addTo(this.map)
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    // async searchLocation() {
    //   this.popupShow = true
    //   const provider = new OpenStreetMapProvider()
    //   this.locationResults = await provider.search({ query: this.location })
    // },
    // filterFn(val, update) {
    //   if (val === '') {
    //     update(() => {
    //       // options.value = stringOptions
    //       // here you have access to "ref" which
    //       // is the Vue reference of the QSelect
    //     })
    //     return
    //   }
    //   update(async () => {
    //     this.loadingLocation = true
    //     const provider = new OpenStreetMapProvider()
    //     this.locationResults = await provider.search({ query: val })
    //     this.loadingLocation = false
    //   })
    // },
    changeMapMarker(item) {
      const latlng = L.latLng(item.raw.lat, item.raw.lon)
      this.marker.setLatLng(latlng)
      this.circle.setLatLng(latlng)
      this.map.panTo({ lon: item.raw.lon, lat: item.raw.lat })
      this.modelValue.address.lat = parseFloat(item.raw.lat)
      this.modelValue.address.long = parseFloat(item.raw.lon)
    },
    onMarkerDrag(e) {
      this.modelValue.address.lat = e.target._latlng.lat
      this.modelValue.address.long = e.target._latlng.lng
      this.circle.setLatLng(e.target._latlng)
    }
  }
}
</script>

<style lang="scss">
.trip-form-subtitle {
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
}
#mapPlace {
  height: 26.25rem !important;
  width: 100% !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
}
.active {
  background: #fff !important;
  color: #000 !important;
}
@media (max-width: 1439px) {
  #mapPlace {
    height: calc(
      250px + (400 - 250) * ((100vw - 320px) / (1440 - 320))
    ) !important;
  }
}
</style>
