<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="project.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="project.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('project.fields.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('project.fields.english')"
                  />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('project.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('project.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.en"
                    :placeholder="i18n('project.fields.english')"
                  ></textarea>
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.description.ar"
                    :placeholder="i18n('project.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('project.fields.address') }}
                </h4>
                <div
                  class="grid grid-cols-12 gap-x-5 lg:col-span-8 gap-y-5 col-span-12"
                >
                  <div class="lg:col-span-6 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="title-en"
                      type="text"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.address.name.en"
                      :placeholder="i18n('project.fields.englishAddress')"
                    />
                  </div>
                  <div class="lg:col-span-6 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="title-ar"
                      type="text"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.address.name.ar"
                      :placeholder="i18n('project.fields.arabicAddress')"
                    />
                  </div>
                  <app-place-form-location
                    v-model="model"
                    :radius="model.radius"
                    :fillColor="model.fillColor"
                    :strokeColor="model.strokeColor"
                  />
                  <div class="lg:col-span-6 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="title-en"
                      type="number"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.address.lat"
                      :placeholder="i18n('project.fields.lat')"
                    />
                  </div>
                  <div class="lg:col-span-6 col-span-12 lg:mt-0 mt-2">
                    <input
                      id="title-ar"
                      type="number"
                      class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                      v-model="model.address.long"
                      :placeholder="i18n('project.fields.lng')"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('project.fields.projectType') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.projectType"
                    class="w-34 sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
                  >
                    <option
                      v-for="(type, index) in projectTypeOptions"
                      :key="index"
                      :value="type"
                    >
                      {{ i18n(`common.${type}`) }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('project.fields.images') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imagesUrls">
                    <div class="flex gap-0 flex-wrap">
                      <div
                        class="relative h-32 w-36"
                        v-for="(image, index) in imagesUrls"
                        :key="index"
                      >
                        <img :src="image" class="object-cover h-full w-full" />
                        <img
                          src="/images/deleteIcon.png"
                          alt=""
                          class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                          @click="deleteImage(index)"
                        />
                      </div>
                    </div>
                    <Uploader
                      ref="uploaderImagesAdd"
                      message="common.addMoreImages"
                      :multiple="true"
                    ></Uploader>
                  </div>
                  <Uploader
                    ref="uploaderImagesNew"
                    :multiple="true"
                    v-else
                  ></Uploader>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/projects/uploader.vue'
import PlaceFormLocation from '@/views/projects/projects-form-location.vue'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader,
    [PlaceFormLocation.name]: PlaceFormLocation
  },
  data() {
    return {
      projectTypeOptions: ['underConstruction', 'launched', 'finished'],
      model: null,
      center: { lat: 31.2357653, lng: 29.95701 },
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'project/form/record',
      findLoading: 'project/form/findLoading',
      saveLoading: 'project/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.$refs.uploaderImagesNew?.filesToSave || []
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.projects')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            this.center = pos
            this.markers = [
              {
                position: pos
              }
            ]
          },
          () => {}
        )
      } else {
        // Browser doesn't support Geolocation handleLocationError(false, infoWindow, map.getCenter());
      }
      this.doNew()
    }
    this.model = this.record || {}
    this.model.name = this.model.name || { en: undefined, ar: undefined }
    this.model.description = this.model.description || {
      en: undefined,
      ar: undefined
    }
    this.model.address = this.model.address || {
      name: {
        en: undefined,
        ar: undefined
      },
      lat: this.center.lat || 31.2357653,
      long: this.center.lng || 29.95701
    }
    this.model.fillColor = this.model.fillColor || '#AFE3E6'
    this.model.strokeColor = this.model.strokeColor || '#CEEAEB'
    this.model.strokeWidth = this.model.strokeWidth || 10
    this.model.radius = this.model.radius || 10
    this.model.projectType = this.model.projectType || ''
    this.model.images = this.model.images || []

    if (!this.isEditing) {
      this.model.projectType = this.projectTypeOptions[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'project/form/doNew',
      doFind: 'project/form/doFind',
      doCreate: 'project/form/doAdd',
      doUpdate: 'project/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (
        this.model.address.name.en.length == 0 ||
        this.model.address.name.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.projectType.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (
        this.model.address.lat.length == 0 ||
        this.model.address.long.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }

      if (this.isEditing) {
        if (
          !(
            this.imagesUrls ||
            this.filesToSaveImagesAdd.length !== 0 ||
            this.filesToSaveImagesNew.length !== 0
          )
        ) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      } else {
        if (this.filesToSaveImagesNew.length === 0) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { name, address, description, projectType } = this.model
      if (this.isEditing) {
        const values = {
          name,
          address: {
            lat: parseFloat(address.lat),
            long: parseFloat(address.long),
            name: address.name
          },
          description,
          projectType,
          images: this.imagesUrls
            ? [...this.imagesUrls, ...this.filesToSaveImagesAdd]
            : this.filesToSaveImagesNew
        }
        await this.doUpdate({
          id: this.id,
          values
        })
      } else {
        await this.doCreate({
          name,
          description,
          projectType,
          address: {
            lat: parseFloat(address.lat),
            long: parseFloat(address.long),
            name: address.name
          },
          images: this.filesToSaveImagesNew
        })
      }
    }
  }
}
</script>
<style lang="scss">
.tom-select {
  .ts-input,
  .ts-dropdown {
    width: 12rem !important;
  }
}
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
